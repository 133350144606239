import styled from 'styled-components';
import dribble from './img/dribble.png';
import { useNavigate } from "react-router-dom";

function NotFound() {
    const navigate = useNavigate();
    const onClickBtn = () => {
        navigate(-1);
    }

    return (
        <SMainDiv>
            <SNotFoundDiv>
                <SDribbleImg src={dribble} />
                <STextDiv>페이지를 찾을 수 없습니다.</STextDiv>
                <SBackButton onClick={onClickBtn}>뒤로 가기</SBackButton>
            </SNotFoundDiv>
        </SMainDiv>
    );
}

export default NotFound;

const SMainDiv = styled.div`
  display: flex;
  width: 100vw;
  max-width: 1222px;
  margin: 0px auto 0px;
  align-items: start;

  @media screen and (max-width: 1220px) {
      max-width: 920px;
  }
`;

const SNotFoundDiv = styled.div`
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  margin-top: 60px;
  text-align: center;
`;

const SDribbleImg = styled.img`

  width: 300px;
  height: 300px;
`;

const STextDiv = styled.div`
  font-size: 20px;
  font-weight: 700;
`;

const SBackButton = styled.button`
    height: 40px;
    width: 80px;

    align-items: center;
    justify-content: center;

    background: #FFFFFF;

    color: #000000;
    font-size: 15px;
    font-weight: 900;

    border: 1px solid black;

    margin-top: 40px;

    cursor: pointer;
`;